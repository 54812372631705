import React from 'react'
import PropTypes from 'prop-types'
import FormField from './FormField'
import useTranslations from '../../../hooks/useTranslations'

const Textarea = ({
  onBlur,
  onChange,
  isDisabled,
  hasError,
  textError,
  label,
  name,
  value,
  placeholder,
  id,
}) => {
  const t = useTranslations()
  return (
    <FormField
      isDisabled={isDisabled}
      label={label}
      hasError={hasError}
      textError={textError}
      labelFor={id}
    >
      <textarea
        className="textarea"
        onBlur={onBlur}
        onChange={onChange}
        name={name}
        value={value}
        placeholder={t(placeholder)}
        id={id}
      />
    </FormField>
  )
}

Textarea.propTypes = {
  isDisabled: PropTypes.bool,
  textError: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default Textarea
