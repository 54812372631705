import React from 'react'
import ContactForm from '../components/forms/ContactForm'

const RequestView = ({ formik }) => {
  return (
    <>
      <ContactForm formik={formik} title="noun:your-request" />
    </>
  )
}

export default RequestView
