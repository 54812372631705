import React from 'react'
import MainLayout from '../layouts/MainLayout'
import RequestView from '../views/RequestView'

const RequestPage = ({ formik }) => {
  return (
    <MainLayout footerTiles={false}>
      <RequestView formik={formik} />
    </MainLayout>
  )
}

export default RequestPage
