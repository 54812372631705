import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import Input from '../form-elements/Input'
import Textarea from '../form-elements/Textarea'
import Button from '../buttons/Button'
import Loader from '../loader/Loader'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'

const ContactForm = ({ title, formik }) => {
  const blockRef = useScrollRevealEffect()
  const t = useTranslations()
  return (
    <div className="contact-form-wrapper wrapper" ref={blockRef}>
      {formik.isSubmitting ? (
        <Loader color="#0092ff" size="100px" />
      ) : (
        <>
          <h2 className="contact-form-title">{t(title)}</h2>
          <form className="contact-form">
            <div className="contact-form-fields">
              <Input
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                type="text"
                label="label:name"
                textError={formik.errors.name && t(formik.errors.name)}
                hasError={Boolean(formik.touched.name && formik.errors.name)}
                placeholder="label:name"
                id="contact_name"
              />
              <Input
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                type="email"
                label="label:email"
                textError={formik.errors.email && t(formik.errors.email)}
                hasError={Boolean(formik.touched.email && formik.errors.email)}
                placeholder="label:email"
                id="contact_email"
              />
            </div>
            <Textarea
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
              label="label:message"
              textError={formik.errors.message && t(formik.errors.message)}
              hasError={Boolean(
                formik.touched.message && formik.errors.message,
              )}
              placeholder="label:my-message"
              id="contact_message"
            />
            <Button
              text="action:submit-request"
              type="submit"
              as="button"
              onClick={formik.handleSubmit}
              size="large"
            />
          </form>
        </>
      )}
    </div>
  )
}

export default ContactForm
